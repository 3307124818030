.icon{
    padding-right: 0.5rem;
}

.about, .our-objectives, .our-roles, .our-vision-mission{
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0rem 4rem;
}

.about-img, .our-roles-img, .our-objectives-img, .our-vision-mission-img{
    width: 50%;
}

.about-content, .our-roles-content, .our-objectives-content, .our-vision-mission-content{
    width: 80%;
}

.our-roles-content li b{
    color: var(--primary-color);
}

.about-img img, .our-roles-img img, .our-objectives-img img, .our-vision-mission-img img{
    width: 70%;
}

p, ul li{
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-text-color);
    text-align: justify;
}

.intro-video{
    display: flex;
    justify-content: center;
}

.about-section{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
}

.about-section h3{
    padding: 1rem 0 0;
}

.sub-heading{
    text-align: center;
}

.our-objectives-section, .our-roles, .vision-mission-section{
    padding: 1rem 4rem;
}

.our-vision-mission h3{
    color: var(--primary-color);
}

.our-roles ul{
    margin: 0 3rem;
}

.our-roles ul li{
    list-style: disc;
}

.see-more-btn{
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

@media only screen and (max-width: 768px) {

    .about{
        flex-direction: column;
        padding: 1rem 2rem;
    }

    iframe{
        padding: 1rem;
    }

    .about-img, .our-objectives-img, .our-vision-mission-img, .our-roles-img{
        width: 100%;
    }

    .our-objectives, .our-vision-mission{
        flex-direction: column-reverse;
        padding: 1rem 2rem;
    }

    .our-roles{
        flex-direction: column;
        padding: 1rem 2rem;
    }

    .our-roles-content{
        width: 100%;
    }

}









