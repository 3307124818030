.img-gallery {
  padding: 5rem 0rem 0rem 0rem;
  display: flex;
}

.img-gallery img {
  width: 100%;
}

.video {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.about-more-QA{
  padding: 6rem 5rem;
  font-family: var(--primary-font);
}

dl dt{
  color: var(--primary-color);
}

dl dd{
  font-size: 16px;
  color: var(--primary-text-color);
}

.indivitual-attention, .suppotive-env, .afford, .exam{
  display: flex;
  align-items: center;
}

.suppotive-env, .exam{
  flex-direction: row-reverse;
}

.indivitual-attention img, .suppotive-env img, .afford img, .exam img{
  width: 30vw;
}

section{
  padding: 1rem;
}

.video img{
  width: 30vw;
  padding: 1rem 0rem;
}

@media screen and (max-width: 768px){

  .indivitual-attention, .suppotive-env, .afford, .exam{
    flex-direction: column;
    text-align: justify;
  }

  .indivitual-attention img, .suppotive-env img, .afford img, .exam img{
    width: 70vw;
  }
  
}


