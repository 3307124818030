.partners{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 4rem;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    align-items: baseline;
}

.partner{
    padding: 1rem;
}

.partner p{
    padding-top: 0.25rem;
    color: rgb(83, 83, 83);
    font-size: small;
    text-align: center;
}

.partners img {
    width: 8rem;
    height: 5rem;
}

#shin{
    width: 5rem;
    height: 5rem;
}

#head{
    width: 10rem;
    height: 3rem;
}

#tokyo, #matsu{
    width: 9rem;
    height: 3rem;
}

#bridge{
    width: 8rem;
    height: 2rem;
}

@media only screen and (max-width: 768px){

    .partners img{
        width: 6rem;
        height: 3rem;
    }
    
    .partners{
        align-items: center;
        justify-content: space-around;
    }

    .partners{
        padding: 1rem 2rem;
    }
 
}

