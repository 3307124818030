.web-nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: var(--secondary-font);
    background-color: #fff;
    padding: 0.75rem 4rem;
    position: fixed;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.aidalogo{
    width: 5rem;
    height: auto;
}

nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
}

nav ul li {
    cursor: pointer;
    color: var(--primary-text-color);
    font-weight: 600;
    font-size: 12px;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
}

nav ul li a {
    cursor: pointer;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
}

nav ul li:hover,
nav ul li a:hover {
    color: var(--primary-color);
}

.nav-cta {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.nav-cta a {
    padding-left: 1rem;
}

.logo a {
    cursor: pointer;
    color: var(--primary-color);
}

.dropdown {
    display: none;
}

.showDropdown {
    display: block;
}

.hideJp {
    display: none;
}

.showJp {
    display: block;
    background: transparent;
    color: var(--primary-text-color);
    padding: 0.25rem 1rem;
    outline: 0.05rem solid var(--primary-text-color);
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    font-weight: 600;
}

.hideEn {
    display: none;
}

.showEn {
    display: block;
    background: transparent;
    color: var(--primary-text-color);
    padding: 0.25rem 1rem;
    outline: 0.05rem solid var(--primary-text-color);
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    font-weight: 600;
}

.web-nav .discover-dropdown,
.web-nav .about-dropdown {
    position: absolute;
    display: block;
}

.discover-dropdown ul,
.about-dropdown ul {
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1rem;
}

@media only screen and (max-width: 768px) {
    .web-nav {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .web-nav {
        display: flex;
    }
}