#contact{
    padding-top: 4rem;
}

.contact-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 4rem;
}

.contact-content ul li {
    list-style: none;
    font-size: 16px;
    padding: 1.25rem 0rem;
    display: flex;
    align-items: center;
}

.enroll-section small {
    color: var(--primary-color);
}

.enroll-section {
    display: flex;
    align-items: center;
}

.enroll-content-section{
    padding: 1rem 4rem;
    font-family: var(--primary-font);
}

.enroll-content-section h3{
    color: var(--primary-color);
}

.social-links a:visited, .contact-icon:link{
    color: var(--primary-text-color);
}

.social-links a:hover{
    color: var(--primary-color);
}

@media only screen and (max-width: 768px) {

    iframe {
        width: 350px;
        height: 300px;
    }

    .contact-content {
        flex-direction: column;
    }

}