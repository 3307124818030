.course-details{
    padding: 6rem;
    width: 100%;
    font-family: var(--primary-font);
}

.course-img-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.course-img-container img{
    width: 35%;
    height: auto;
}

