.courses{
    display: flex;
}

.course-content{
    padding: 4rem;
}

@media only screen and (max-width: 768px) {
    .courses{
        flex-direction: column;
    }

    .course-content{
        padding: 1rem 2rem;
    }
}
    
