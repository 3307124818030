.img-container{
    width: 100%;
    height: 75vh;
}

.img-container img{
    width: 100%;
    height: 75vh;
}

@media only screen and (max-width: 768px) {

    .img-container img{
        height: 30vh;
    }

    .img-container{
        height: 30vh;
    }
    

}