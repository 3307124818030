.mobile-nav {
  padding: 1rem;
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-nav ul {
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.hideMobileNav {
  display: none;
}

.showMobileNav{
  display: block;
}

.toggle-btn {
  cursor: pointer;
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-text-color);
  margin-left: 1rem;
}

.about-dropdown,
.discover-dropdown {
  display: none;
}

.dropdown{
  display: none;
}

.showDropdown{
  display: block;
}

.discover-dropdown, .about-dropdown{
  position: relative;
  display: block;
}

@media only screen and (max-width: 768px) {
  .mobile-nav {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}