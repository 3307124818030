header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('../assets/bg2.png');
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
}

.header-title {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: var(--secondary-font);
}

.header-title h1 {
    color: #fff;
    font-size: 4rem;
    font-weight: 700;
}

.header-title h3 {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
}

.header-title h3 span {
    color: var(--primary-color);
}

.cta-btn {
    background: transparent;
    color: #fff;
    padding: 0.5rem 1rem;
    outline: 0.05rem solid #fff;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    font-weight: 600;
    font-family: var(--secondary-font);
}

.counters {
    display: flex;
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--primary-font);
    color: #fff;
}

.counter {
    padding: 0 2rem;
    text-align: center;
}

.counter p {
    color: #fff;
}

.plus {
    color: var(--primary-color);
}

@media only screen and (max-width: 768px) {

    .header-title h1 {
        font-size: 2rem;
    }

    .header-title h3 {
        font-size: 1rem;
    }

    .counter h1 {
        display: flex;
        justify-content: center;
    }

}