footer{
    padding: 1rem 2rem;
    font-family: var(--primary-font);
    background-color: var(--primary-text-color);
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    width: 100%;
}

footer a:visited, footer a{
    color: #fff;
}

.footer-navlinks, .social-links{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.footer-navlinks{
    padding: 1rem 0;
}

footer ul li{
    list-style: none;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}

footer ul h3{
    font-family: var(--primary-font);
    color: var(--primary-color);
    font-weight: 700;
}

.logo{
    cursor: pointer;
    width: 8rem;
    height: auto;
}

small{
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    color: rgba(255, 255, 255, 0.308);
}

hr{
    border: 0.05px solid rgba(255, 255, 255, 0.308);
}

small a:visited, small a:link{
    color: rgba(255, 255, 255, 0.308);
    font-size: 12px;
  }

  .social-links{
    padding-top: 1rem;
}

.social-links li{
    list-style: none;
    color: #fff;
}

@media screen and (max-width: 768px) {
    
        .footer-navlinks{
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        footer ul li{
            text-align: center;
        }
    
}