@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  --primary-font: 'Poppins', sans-serif;
  --secondary-font: 'Poppins', sans-serif;
  --primary-color: #1678F2;
  --secondary-color: #974AD4;
  --primary-text-color: #1E1E1E;
  --bg-color: #F5F5F5;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-decoration: none;
}

.primary-btn {
  background: linear-gradient(90deg, rgba(22, 120, 242, 1) 0%, rgba(151, 74, 212, 1) 78%);
  color: #fff;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-family: var(--secondary-font);
  font-weight: 600;
}

.secondary-btn {
  background: transparent;
  color: var(--primary-text-color);
  padding: 0.25rem 1rem;
  outline: 0.05rem solid var(--primary-text-color);
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: 600;
  font-family: var(--secondary-font);
}

.sub-heading {
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--primary-text-color);
  background: -webkit-linear-gradient(var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}



