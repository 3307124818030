#staff{
  padding-top: 5rem;
}

.about-more-content{
    padding: 6rem;
    font-family: var(--primary-font);
  }

  .principal-img{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
  }

  .principal-img h6{
    text-align: center;
    color: black;
  }

  .principal-img p{
    font-size: small;
  }

  .principal-img img{
    width: 25%;
    height: auto;
  }

  .begin p{
    text-align: left;
    padding: 1rem 0;
  }

  .end p{
    text-align: right;
    padding: 1rem 0;
  }

  .academic-staff{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 5rem;
    font-family: var(--primary-font);
  }

  .academic-staff-img{
    margin: 1.5rem;
    -webkit-box-shadow: 1px 3px 17px -1px rgba(0,0,0,0.73); 
    box-shadow: 1px 3px 17px -1px rgba(0,0,0,0.73);
    border-radius: 0.5rem;
    padding: 1rem;
  }

  .academic-staff-img p{
    font-size: small;
  }

  .academic-staff-img img{
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    width: 200px;
    height: 200px;
  }


  @media only screen and (max-width: 768px) {

    .academic-staff{
      flex-direction: column;
    }

    .principal-img img{
      width: 50vw;
      height: auto;
    }

}