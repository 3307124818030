.testimonials{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 4rem;
    margin: 2rem;
}

.info{
    display: block;
    text-align: left;
    padding-left: 1rem;
}

.info h3{
    color: var(--primary-color);
}

.info h6{
    color: var(--primary-text-color);
    font-weight: bold;
}

.testimonial-content{
   margin: 0.25rem 0.5rem;
   font-family: var(--primary-font);
   width: 45%;
}

@media only screen and (max-width: 768px) {

    .testimonials{
        flex-direction: column;
        padding: 1rem 2rem;
    }

    .testimonial-content{
        width: 100%;
        padding: 0.5rem;
    }

}
    
