.enroll-form-content {
    padding: 6rem;
}

.exams {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

table {
    width: 80%;
    padding: 2rem;
    font-family: var(--primary-font);
}

table td{
    padding: 0.5rem;
}

.enroll-form-content h3, h5{
    font-family: var(--primary-font);
}

@media only screen and (max-width: 768px) {

    .exams {
        flex-direction: column;
    }

    .enroll-form-content {
        padding: 6rem 3rem;
    }

}